import React from 'react';
import "../styles/Attribution.css"

export default function Attribution() {
    return  (
        <footer className="footer">
            <div className="footerContainer">
                <p className="copyright">© 2024 Cipher Moon</p>
            </div>
        </footer>
    );
}