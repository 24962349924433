import React from 'react';
import "../../../styles/PrivacyPolicy.css"

export default function AdventuresOfYippeePrivacy() {
    return (
        <div style={{backgroundColor: '#4c4747'}}>
            <h1 className="title">
                Adventures of Yippee! The Yippest - Privacy
            </h1>
            <br/>
            <br/>
            <div className="privacy-policy">
                <p>The "Adventures of Yippee! The Yippest" app does not collect any information or user data. No personal information is tracked or stored as part of playing this game.</p>
                <p>For any app that is available on Apple's App Store or Mac App Store, users are subject to Apple's <a href="https://developer.apple.com/app-store-connect/analytics/">App Analytics</a> which does not contain any personal information and is anonymous.</p>
            </div>
        </div>
    )
}