import React from 'react';
import "../../../styles/Common.css"
import "../../../styles/Apps.css"

const AdventuresOfYippeeAppIcon = require("../../../images/app_icons/AdventuresOfYippeeAppIcon.png")

export default function AdventuresOfYippeeHome() {
    return (
        <>
            <h1 className="common-title">
                Adventures of Yippee! The Yippest
            </h1>
            <br/>
            <img className="app-icon-large" alt="adventures of yippee the yippest app icon" src={AdventuresOfYippeeAppIcon}/>
            <h5 className="app-description">Little Yippee traveling distant lands!
            Play as Yippee and see how far you can travel! There's some mean Yippees out there, but you fight for justice!</h5>
            <br/>
            <br/>
        </>
    )
}