import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, Grid, Paper } from '@mui/material';
import emailjs from '@emailjs/browser';
import '../../styles/Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    display: false,
    message: '',
    type: '',
  });

  const [emailJsConfig, setEmailJsConfig] = useState({
    serviceId: '',
    templateId: '',
    userId: ''
  });

  useEffect(() => {
    const fetchEmailJsConfig = async () => {
      try {
        const response = await fetch('https://<your-worker-subdomain>.workers.dev'); // Adjust the path accordingly
        const data = await response.json();
        setEmailJsConfig(data);
      } catch (error) {
        console.error('Error fetching EmailJS config:', error);
      }
    };

    fetchEmailJsConfig();
  }, []);

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Please enter your name";
    if (formData.name.length > 30) tempErrors.name = "Please use 30 characters or less";
    if (!formData.email) tempErrors.email = "Please enter your email address";
    if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.email)) tempErrors.email = "Please enter a valid email address";
    if (!formData.subject) tempErrors.subject = "Please enter a subject";
    if (formData.subject.length > 75) tempErrors.subject = "Subject cannot exceed 75 characters";
    if (!formData.message) tempErrors.message = "Please enter a message";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (alertInfo.display) {
      validate();
    }
  };

  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      toggleAlert('Please correct the errors above and try again.', 'danger');
      return;
    }

    const { name, email, subject, message } = formData;
    try {
      setDisabled(true);

      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      await emailjs.send(
        emailJsConfig.serviceId,
        emailJsConfig.templateId,
        templateParams,
        emailJsConfig.userId,
      );

      toggleAlert('Form submission was successful!', 'success');
    } catch (e) {
      console.error(e);
      toggleAlert('Uh oh. Something went wrong.', 'danger');
    } finally {
      setDisabled(false);
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      setErrors({});
    }
  };

  return (
    <Container maxWidth="sm" className="container">
      <Paper className="paper">
        <Typography variant="h4" align="center" gutterBottom style={{ color: '#000', fontWeight: 'bold' }}>
          Contact
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-field"
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-field"
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="form-field"
                error={!!errors.subject}
                helperText={errors.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="form-field"
                error={!!errors.message}
                helperText={errors.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                className="submit-button"
                disabled={disabled}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        {alertInfo.display && (
          <div className={`alert alert-${alertInfo.type} mt-3`} role="alert">
            {alertInfo.message}
          </div>
        )}
      </Paper>
      <Box mt={1} className="text-background" p={2}>
      <Grid item xs>
        <Typography align="center" style={{ color: '#fff', fontWeight: 'bold' }}>
            Or send an email directly to contact@ciphermoon.com
        </Typography>
        </Grid>
      </Box>
    </Container>
  );
};

export default Contact;
