import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-logo">
          <img src="/ciphermoonlogo192.png" width="50" height="50" alt="Logo" />
        </Link>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Apps <i className="fas fa-caret-down"/>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/privacy" className="nav-links" onClick={closeMobileMenu}>
              Privacy <i className="fas fa-caret-down"/>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              Contact <i className="fas fa-caret-down"/>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/support" className="nav-links" onClick={closeMobileMenu}>
              Support <i className="fas fa-caret-down"/>
            </Link>
          </li>
        </ul>
      </div>
      <div className="menu-icon" onClick={handleClick}>
        <i className={click ? "fas fa-times" : "fas fa-bars"} />
      </div>
    </nav>
  );
}

export default Navbar;
