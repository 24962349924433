import React from "react";
import { Container, Typography, Box, Grid, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import "../../styles/Home.css";

const AdventuresOfYippeeAppIcon = require('../../images/app_icons/AdventuresOfYippeeAppIcon.png')

export default function Home() {
  return (
    <Container className="home-container">
      <Box className="text-background" p={2}>
        <Typography variant="h1" className="home-title" gutterBottom>
          Cipher Moon
        </Typography>
        <Typography variant="h6" className="home-subtitle" gutterBottom>
          Cipher's Apps in the Cybersphere
        </Typography>
      </Box>
      <Box mt={4} className="text-background" p={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Link component={RouterLink} to="/adventuresofyippee" underline="none">
              <img src={AdventuresOfYippeeAppIcon} alt="App Icon" className="app-icon" />
            </Link>
          </Grid>
          <Grid item xs>
            <Typography variant="h5" className="app-title">
              Adventures of Yippee! The Yippest
            </Typography>
            <Typography variant="body1">
              Adventurous Yippee sets out to explore distant lands! Fight through the enemy Yippees and see how far you can get!
            </Typography>
            <Box mt={2} className="link-container">
                <Link href="/adventuresofyippee" className="app-link">
                <b>Learn More</b>
              </Link>
              <span className="link-separator">|</span>
              <Link href="/adventuresofyippee/privacy" className="app-link">
                <b>Privacy Policy</b>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
