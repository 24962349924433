import React from 'react';
import { Link } from 'react-router-dom';
import "../../styles/AppPrivacy.css";

const AdventuresOfYippeeAppIcon = require("../../images/app_icons/AdventuresOfYippeeAppIcon.png");

export default function AppPrivacy() {
    return (
        <>  
            <h1 className="common-title">
                Privacy
            </h1>
            <br/>
            <br/>
            <table>
                <thead>
                    <tr>
                        <th><b>Name</b></th>
                        <th><b>App Icon</b></th>
                        <th><b>Privacy Policy</b></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="app-row">
                        <td className="app-title">Adventures of Yippee! The Yippest</td>
                        <td><img className="app-icon" alt="adventures of yippee app icon" src={AdventuresOfYippeeAppIcon}/></td>
                        <td><Link to="/adventuresofyippee/privacy">Link</Link></td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}