import Navbar from "./components/Navbar"
import { Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import Apps from "./components/pages/Apps";
import AppPrivacy from "./components/pages/AppPrivacy";
import Attribution from "./components/Attribution";
import './styles/App.css';
import AdventuresOfYippeeHome from "./components/pages/apps/AdventuresOfYippee";
import AdventuresOfYippeePrivacy from "./components/pages/privacy/AdventuresOfYippeePrivacy";
import Contact from "./components/pages/Contact";
import Support from "./components/pages/Support";

function App() {
  return (
    <>
        <Navbar/>
        <div className="bg-container">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/apps" element={<Apps />} />
                <Route path="/privacy" element={<AppPrivacy />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/support" element={<Support />} />
                <Route path="/adventuresofyippee" element={<AdventuresOfYippeeHome />} />
                <Route path="/adventuresofyippee/privacy" element={<AdventuresOfYippeePrivacy />} />
            </Routes>
        </div>
        <Attribution />
    </>
  );
}

export default App;
