import React from 'react';
import { Link } from 'react-router-dom';
import "../../styles/Common.css"
import "../../styles/Apps.css"

const AdventuresOfYippeeAppIcon = require("../../images/app_icons/AdventuresOfYippeeAppIcon.png")

export default function Apps() {
    return (
        <>  
            <h1 className="common-title">
                Apps
            </h1>
            <br/>
            <br/>
            <table>
                <thead>
                    <tr>
                        <th><b>Name</b></th>
                        <th><b>App Icon</b></th>
                        <th><b>App Store Link</b></th>
                        <th><b>More Information</b></th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ border: "1px solid black" }}>
                        <td>Adventures of Yippee! The Yippest</td>
                        <td><img className="app-icon" alt="adventures of yippee the yippest app icon" src={AdventuresOfYippeeAppIcon}/></td>
                        <td><Link to="https://www.google.com" target="_blank">Link</Link></td>
                        <td><Link to="/adventuresofyippee">Link</Link></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}