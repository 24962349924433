import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import "../../styles/Support.css";

export default function Support() {
    return (
        <>  
            <h1 className="common-title">
                Support
            </h1>
            <br/>
            <br/>
            <Box mt={4} className="text-background" p={2}>
                <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Typography variant="body1">
                    <b>Contact:</b> Please feel free to reach out for any inquiries or support needs at our <b><a href="/contact" style={{ color: 'green', textDecoration: 'none' }}>Contact Page</a></b>.
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                    <b>Refunds:</b> Refunds can only be done through Apple as we unfortunately do not have the ability to do this. For information on how to do this, please refer this link: <b><a href="https://support.apple.com/en-us/118223" style={{ color: 'green', textDecoration: 'none' }}>Apple Refund Request</a></b>
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                    <b>Privacy:</b> Please see this privacy policy which applies to the Yippee app currently available: <b><a href="/adventuresofyippee/privacy" style={{ color: 'green', textDecoration: 'none' }}>Privacy Policy</a></b>.
                    </Typography>
                </Grid>
                </Grid>
            </Box>
        </>
    );
}
